<template>
    <div class="content">

        <h1>Upload your project</h1>

        <div v-if="currentUser">
            <p>Please fill in the project information. Fields marked with * are mandatory.</p>
            <el-row>
                <el-col :span="24">
                    <el-form ref="ruleForm" :rules="rules" :model="form" label-width="120px">
                        <el-form-item label="Project name*" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Description*" prop="desc">
                            <el-input type="textarea" :rows="15" v-model="form.desc"></el-input>
                        </el-form-item>
                        <el-form-item label="Tags">
                            <el-tag :key="tag" v-for="tag in form.tags" closable :disable-transitions="false" @close="handleClose(tag)">
                                {{tag}}
                            </el-tag>
                            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="mini" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>

                        </el-form-item>
                        <el-form-item label="Category*" prop="category">
                            <span v-show="!categories || categories.length == 0">Loading categories...</span>
                            <el-select v-model="form.category" placeholder="Select" v-loading="!categories" v-show="categories && categories.length > 0">
                                <el-option v-for="item in categories" :key="item.value" :label="item.label" :value="item._id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Project documentation">
                            <el-input v-model="form.website" placeholder="http://..."></el-input>
                        </el-form-item>

                    </el-form>
                </el-col>
                <el-col :span="24">
                    <el-card style="margin-left: 24px" v-loading="avatarUploading">

                        <div slot="header">
                            <strong>Thumbnail*</strong>
                            <span style="float: right"> PNG or JPEG, 72dpi, 512x512</span>
                        </div>
                        <ImagePicker uploadFolder="library/projects" :src="form.imageUrl" :width="512" :height="512" v-on:image="avatarSelected" />

                    </el-card>
                    <el-card style="margin-left: 24px; margin-top: 24px">
                        <div slot="header">
                            <strong>Detail photos *</strong>
                            <span style="float: right"> PNG or JPEG, 72dpi, 1024x1024</span>
                        </div>
                        <p>
                            <strong>One photo required</strong>, but you can load up to 4 photos.
                        </p>
                        <ImagePicker style="float: left;margin-right: 12px;" uploadFolder="library/projects" :width="1024" :height="1024" v-on:image="thumbnailSelected" v-for="i in 4" :key="i" :index="i" />
                        <div style="clear: both"></div>
                    </el-card>
                    <el-card style="margin-left: 24px; margin-top: 24px">
                        <div slot="header">
                            <strong>Downloads*</strong>
                            <span style="float: right"> Max 2MB</span>
                        </div>
                        <FilePicker v-on:fileSelected="fileSelected" v-on:fileUploading="fileUploading" v-on:fileChanged="fileChanged" :initialFiles="[]" />
                    </el-card>
                </el-col>
            </el-row>
            <div style="float: right;margin-top: 24px">
                <el-form :model="form" style="text-align: right">
                    <el-form-item>
                        <el-button type="success" :disabled="!submitEnabled" @click="submitForm('ruleForm')">Upload</el-button>
                        <el-button @click="resetForm('ruleForm')">Cancel</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div v-else>
            <p>You need to be logged in to upload</p>
            <router-link :to="{name:'login'}">Login</router-link>
        </div>
    </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import ImagePicker from './ImagePicker.vue'
import FilePicker from './FilePicker.vue'


let storage, uploadFolder, db, categoriesRef, projectsRef

export default {
    name: 'upload-project',
    components: {
        ImagePicker, FilePicker
    },
    created() {
        storage = firebase.storage()
        db = firebase.database()
        categoriesRef = db.ref('library').child('categories')
        projectsRef = db.ref('library').child('projects')
        uploadFolder = storage.ref('library/projects')
        this.$bindAsArray('categories', categoriesRef, null, function() {
            console.log('categories loaded')
        })
        this.$bindAsArray('projects', projectsRef, null, function() {
            console.log('projects loaded')
        })

        const _this = this
        firebase.auth().onAuthStateChanged(function(user) {
            _this.currentUser = user
        })

    },

    data() {
        return {
            currentUser: firebase.auth().currentUser,
            rules: {
                name: [
                    { required: true, message: 'Please input Project name', trigger: 'blur' },
                    { min: 3, max: 255, message: 'Length should be 3 to 255', trigger: 'blur' }
                ],
                desc: [
                    { required: true, message: 'Please input Description', trigger: 'blur' },
                    // { min: 50, max: 1000, message: 'Length should be 50 to 1000 characters', trigger: 'blur' }
                ],
                category: [
                    { required: true, message: 'Please select a category', trigger: 'blur' }
                ]
            },
            showMore: false,
            form: {
                name: '',
                desc: '',
                category: '',
                tags: [],
                website: '',
                downloads: ''
            },
            inputValue: '',
            inputVisible: false,
            imageUrl: '',
            imageRef: {},
            imageList: {},
            fileList: [],
            avatarUploading: false,
        }
    },
    computed: {
        submitEnabled: function() {
            return (this.form.name != '' && this.form.desc != '' && this.form.imageUrl != '')
        },
        uploadedFiles: function() {
            const files = this.fileList.map(function(file, idx) {
                const metadata = file.response.metadata
                return {
                    bucket: metadata.bucket,
                    fullPath: metadata.name,
                    name: file.name,
                    url: metadata.mediaLink,
                    size: metadata.size,
                    contentType: metadata.contentType
                }
            })
            return files
        }
    },
    methods: {
        shouldShowMore() {
            this.showMore = true;
        },
        avatarSelected(image) {
            // alert('yay')
            this.form.imageUrl = image.url
            this.imageRef = image
        },
        thumbnailSelected(image, key) {
            this.imageList[key] = image
        },
        fileSelected(files) {
            this.fileList = files
            this.filesUploading = false
        },
        fileChanged(files) {
            this.fileList = files
        },
        fileUploading(files) {
            this.filesUploading = true
        },
        handleClose(tag) {
            this.form.tags.splice(this.form.tags.indexOf(tag), 1);
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.form.tags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        submitForm(formName) {
            const _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    const auth = firebase.auth();
                    const user = auth.currentUser;
                    const newKey = this.$firebaseRefs.projects.push().key



                    const projectData = {
                        name: this.form.name,
                        description: this.form.desc,
                        category: this.form.category,
                        tags: this.form.tags,
                        website: this.form.website,
                        thumbnail: this.form.imageUrl,
                        photos: this.imageList,
                        // downloads: this.fileList,
                        userId: user.uid,
                        username: user.displayName,
                        userAvatar: user.photoURL,
                        created: new Date().toUTCString(),
                        files: this.uploadedFiles
                    }
                    const db = firebase.database().ref()
                    const updates = {}
                    updates['/library/projects/' + newKey] = projectData
                    updates['/library/profiles/' + user.uid + '/projects/' + newKey] = projectData
                    updates['/library/recent/' + newKey] = projectData
                    db.update(updates)
                        .then(function(res) {
                            // alert('Success')

                            _this.$message('Project uploaded')
                            _this.$router.push({ 'name': 'profile' })
                            // console.log(res)
                        }).catch(function(error) {
                            // alert('Error')
                            _this.$message('Error uploading project, please try again')
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$router.push('/profile')
        }
    }
}
</script>
<style scoped>
.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>
